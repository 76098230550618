<template>
  <svg :width="width" :height="height" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g class="position-relative">
      <rect :width="width" :height="height" :style="'fill:'+color+';'" />
      <text x="35%" y="50%" font-family="Lato" font-size="20" fill="#4a25aa">{{width}}/{{height}}</text>
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      width: {default: "100px"},
      height: {default: "100px"},
      color: {default: "rgb(204, 204, 204)"},
    }
  }
</script>
