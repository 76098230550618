<template>
  <div :class="`image-placeholder ${isRounded}`" class="image">
    <v-lazy-image
      :src="!$store.state.home.device.isDesktop && imageRequiredMobile ? imageRequiredMobile : imageRequired"
      :width="!$store.state.home.device.isDesktop && imageRequiredMobile ? widthMobile : width"
      :height="!$store.state.home.device.isDesktop && imageRequiredMobile ? heightMobile : height"
      :src-placeholder="
        !$store.state.home.device.isDesktop && imageRequiredMobile ? '/storage/images/dummy-lazy-load-2.webp' : '/storage/images/dummy-banner-2.webp'
      "
      :alt="alt"
    />
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    VLazyImage,
  },
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    imageUrlMobile: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    widthMobile: {
      type: String,
      default: "",
    },
    heightMobile: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    fromApi: {
      type: Boolean,
      default: false,
    },
    fromUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadImage: false,
      errorImage: false,
    };
  },
  computed: {
    imageRequired() {
      if (this.errorImage) {
        return "/storage/images/dummy-banner.webp";
      } else {
        if (this.fromApi === false) {
          if (this.fromUrl === false) {
            return `/storage/images/${this.imageUrl}`;
          } else {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.width ? this.width : "500"
            }:${this.height ? this.height : "117"}:1/gravity:ce/plain/${
              this.imageUrl
            }@webp`;
          }
        } else {
          return `https://images.narasi.tv/preset:sharp/resize:fill:${
            this.width ? this.width : "500"
          }:${this.height ? this.height : "117"}:1/gravity:ce/plain/${
            this.imageURLAPI
          }${this.imageUrl}@webp`;
        }
      }
    },
    imageRequiredMobile() {
      if (this.errorImage) {
        return `/storage/images/dummy.webp`;
      } else {
        if (this.fromApi === false) {
          if (this.fromUrl === false) {
            if (this.imageUrlMobile !== "") {
              return `/storage/images/${this.imageUrlMobile}`;
            } else {
              return `/storage/images/${this.imageUrl}`;
            }
          } else {
            if (this.imageUrlMobile !== "") {
              return `https://images.narasi.tv/preset:sharp/resize:fill:${
                this.widthMobile ? this.widthMobile : "500"
              }:${
                this.heightMobile ? this.heightMobile : "280"
              }:1/gravity:ce/plain/${this.imageUrlMobile}@webp`;
            } else {
              return `https://images.narasi.tv/preset:sharp/resize:fill:${
                this.widthMobile ? this.widthMobile : "500"
              }:${
                this.heightMobile ? this.heightMobile : "280"
              }:1/gravity:ce/plain/${this.imageUrl}@webp`;
            }
          }
        } else {
          if (this.imageUrlMobile !== "") {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.widthMobile ? this.widthMobile : "500"
            }:${
              this.heightMobile ? this.heightMobile : "280"
            }:1/gravity:ce/plain/${this.imageURLAPI}${
              this.imageUrlMobile
            }@webp`;
          } else {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.widthMobile ? this.widthMobile : "500"
            }:${
              this.heightMobile ? this.heightMobile : "280"
            }:1/gravity:ce/plain/${this.imageURLAPI}${this.imageUrl}@webp`;
          }
        }
      }
    },
    isRounded() {
      return this.rounded ? "image-placeholder--rounded" : "";
    },
    imageURLAPI() {
      return process.env.IMAGE_URL;
    },
  },
  methods: {
    imageError(e) {
      this.errorImage = true;
    },
  },
};
</script>

<style scoped lang="scss">
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
.image {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    &.no-blur {
      filter: unset !important;
    }
  }
  &.image-placeholder {
    overflow: hidden;
    line-height: 0;

    &--rounded {
      border-radius: 100%;
    }
  }
}
</style>
