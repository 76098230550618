<template>
  <div :class="`image-placeholder ${isRounded}`" class="image">
    <picture>
      <v-lazy-image
        v-if="!$store.state.home.device.isDesktop && imageRequiredMobile"
        :src="imageRequiredMobile"
        src-placeholder="/storage/images/dummy-lazy-load-2.webp"
        :alt="alt"
      />
      <v-lazy-image
        v-if="$store.state.home.device.isDesktop && imageRequired"
        :src="imageRequired"
        :data-loading="imageRequired.placeholder"
        src-placeholder="/storage/images/dummy-lazy-load-2.webp"
        :alt="alt"
        :class="{ 'no-blur': errorImage }"
        @error="imageError"
      />
    </picture>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    VLazyImage,
  },
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    imageUrlMobile: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    widthMobile: {
      type: String,
      default: "",
    },
    heightMobile: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    fromApi: {
      type: Boolean,
      default: false,
    },
    fromUrl: {
      type: Boolean,
      default: false,
    },
    gravity: {
      type: String,
      default: "ce",
    },
  },
  data() {
    return {
      loadImage: false,
      errorImage: false,
    };
  },
  computed: {
    imageRequired() {
      if (this.errorImage) {
        return `/storage/images/dummy-square2.png`;
      } else {
        if (this.fromApi === false) {
          if (this.fromUrl === false) {
            return `/storage/images/${this.imageUrl}`;
          } else {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.width ? this.width : "312"
            }:${this.height ? this.height : "174"}:1/gravity:${
              this.gravity
            }/plain/${this.imageUrl}@webp`;
          }
        } else {
          return `https://images.narasi.tv/preset:sharp/resize:fill:${
            this.width ? this.width : "312"
          }:${this.height ? this.height : "174"}:1/gravity:${
            this.gravity
          }/plain/${this.imageURLAPI}${this.imageUrl}@webp`;
        }
      }
    },
    imageRequiredMobile() {
      if (this.errorImage) {
        return `/storage/images/dummy-square2.png`;
      } else {
        if (this.fromApi === false) {
          if (this.fromUrl === false) {
            if (this.imageUrlMobile !== "") {
              return `/storage/images/${this.imageUrlMobile}`;
            } else {
              return `/storage/images/${this.imageUrl}`;
            }

            // return require(`~/static/${this.imageUrlMobile}`)
          } else {
            if (this.imageUrlMobile !== "") {
              return `https://images.narasi.tv/preset:sharp/resize:fill:${
                this.widthMobile ? this.widthMobile : "250"
              }:${this.heightMobile ? this.heightMobile : "140"}:1/gravity:${
                this.gravity
              }/plain/${this.imageUrlMobile}@webp`;
            } else {
              return `https://images.narasi.tv/preset:sharp/resize:fill:${
                this.widthMobile ? this.widthMobile : "250"
              }:${this.heightMobile ? this.heightMobile : "140"}:1/gravity:${
                this.gravity
              }/plain/${this.imageUrl}@webp`;
            }
          }
        } else {
          if (this.imageUrlMobile !== "") {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.widthMobile ? this.widthMobile : "250"
            }:${this.heightMobile ? this.heightMobile : "140"}:1/gravity:${
              this.gravity
            }/plain/${this.imageURLAPI}${this.imageUrlMobile}@webp`;
          } else {
            return `https://images.narasi.tv/preset:sharp/resize:fill:${
              this.widthMobile ? this.widthMobile : "250"
            }:${this.heightMobile ? this.heightMobile : "140"}:1/gravity:${
              this.gravity
            }/plain/${this.imageURLAPI}${this.imageUrl}@webp`;
          }
        }
      }
    },
    isRounded() {
      return this.rounded ? "image-placeholder--rounded" : "";
    },
    imageURLAPI() {
      return process.env.IMAGE_URL;
    },
  },
  methods: {
    imageError(e) {
      this.errorImage = true;
      // e.target.src = require(`~/static/storage/images/dummy.webp`);
    },
  },
};
</script>

<style scoped lang="scss">
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
.image {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  &.img-presisi {
    height: 365px;
    object-fit: cover;
    img {
      width: 100%;
      height: 365px;
      object-fit: cover;
    }
  }
  &.img-small {
    height: 100px;
    width: 100px;
    object-fit: cover;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  &.img-populars {
    width: 305px;
    height: 184px;
    object-fit: cover;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 207px;
    }
    img {
      width: 305px;
      height: 184px;
      object-fit: cover;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        height: 207px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    &.no-blur {
      filter: unset !important;
    }
  }
  &.image-placeholder {
    overflow: hidden;
    line-height: 0;

    &--rounded {
      border-radius: 100%;
    }
  }
}
</style>
